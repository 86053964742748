/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  QueryClient,
  QueryClientProvider,
  useQueryClient,
} from "@tanstack/react-query";
import React, { useState, useEffect } from "react";
import {
  Route,
  redirect,
  useLocation,
  useNavigate,
  Navigate,
  BrowserRouter,
  Routes,
} from "react-router-dom";
import Home from "./pages/Home";
import Landing from "./pages/Landing";
import Creator from "./pages/Creator";
import Settings from "./pages/Settings";
import Header from "./components/Header";
// import { UserProvider, useUser } from './contexts/UserContext'
import Subscriptions from "./pages/Suscriptions";
import Revenues from "./pages/Revenues";
import { User } from "./api/user";
import ProtectedForYou from "./pages/ForYou";
import Login from "./pages/admin/Login";
// import Loader from './components/design-system/Loader'
// import { ProtectedComponent } from './components/ProtectedComponent'
// import Numbers from './pages/admin/Numbers'
// import { User } from './api/user'

// const Router = () => {
//   return (
//     <>
//       <BrowserRouter>
//         <Route path="" element={<Landing/>}/>
//         <Route path="/app" element={<ProtectedForYou/>} />
//       </BrowserRouter>

//       </>
//     )
// }
{
  /* 
        <Header/>
        <div className='pt-20'>
        <Routes>
        <Route path="/" element={<Landing />} />

        <Route path="home" element={<Home />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/creator/:id" element={<Creator />} />

        </Routes>
        </div> */
}

// {!user ?(

//   <Routes>
//     <Route path="/" element={<Landing />} />
//   </Routes>
// ):(
//   <>
//   <Header/>
//   <div className='pt-20'>
//   <Routes>
//   <Route path="home" element={<Home />} />
//   <Route path="/settings" element={<Settings />} />
//   <Route path="/creator/:id" element={<Creator />} />

//   </Routes>
//   </div>
//   </>
// )
// }

const App: React.FC = () => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        cacheTime: 1000 * 60 * 60 * 1, // 1 hour
      },
    },
  });

  const subdomain = window.location.hostname.split(".")[0];

  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        {/* {subdomain === 'admin' ? (
            <Routes>
              <Route path="/discover" element={<Login/>}/>
              <Route path='/' element={ <ProtectedForYou/>} />
             </Routes>
          ) : ( */}
        {/* // <><p>LANDING</p></> */}
        <Routes>
          <Route path="/discover" element={<Landing />} />
          <Route path="/*" element={<ProtectedForYou />} />
        </Routes>
        {/* )} */}
      </BrowserRouter>
    </QueryClientProvider>
  );
};

export default App;

{
  /* <Route path="/login" element={<Login handleLogin={handleLogin} />} />
      <Route path="/signup" element={<Signup handleLogin={handleLogin} />} /> */
}
{
  /* <Route path="/landing" element={<Landing />} /> */
}
{
  /* {isAuthenticated ? (
        <Route path="/" element={<Home user={user} handleLogout={handleLogout} />} />
      ) : (
        redirect('/login')
      )}
      {() => redirect('/landing')} */
}

//   const [isAuthenticated, setIsAuthenticated] = useState(false)
//   const [user, setUser] = useState<any | null>(null)
//   const location = useLocation()

//   useEffect(() => {
//     async function checkAuth() {
//       const response = await axios.get('/api/auth/check')
//       if (response.data.authenticated) {
//         setIsAuthenticated(true)
//         setUser(response.data.user)
//       }
//     }
//     checkAuth()
//   }, [])

//   const handleLogin = async (email: string, password: string) => {
//     try {
//       const response = await axios.post('/api/auth/sign_in', {
//         email,
//         password,
//       })
//       setIsAuthenticated(true)
//       setUser(response.data.user)
//     } catch (error) {
//       console.error(error)
//     }
//   }

//   const handleLogout = async () => {
//     try {
//       await axios.delete('/api/auth/sign_out')
//       setIsAuthenticated(false)
//       setUser(null)
//     } catch (error) {
//       console.error(error)
//     }
//   }
