import { useQuery } from '@tanstack/react-query';
import axios from 'axios';


const NUMBERS_KEY = ['admin', 'numbers']

export const useNumbers = () => useQuery(NUMBERS_KEY, async () => {
  const response = await axios.get('/admin/numbers')

  return response.data
})