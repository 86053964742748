import axios from 'axios'

export type Duration = 'day' | 'week' | 'month'
export interface Subscription {
  id: string
  profile_id: string
  profile_pic: string
  price: string
  expires_in: string | null
  duration: Duration
  profile_name: string
}

export interface SuscribePayload {
  profile_id: string
  duration: Duration
}

export const suscribe = async (payload: SuscribePayload) => {
  const response = await axios.post('/subscriptions', {
    ...payload
  })

  return response
}

export const getSubscripions = async () => {
  const response = await axios.get('/subscriptions')

  return response
}