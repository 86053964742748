interface LabelProps {
  label: string
  required?: boolean
  children?: JSX.Element
  align?: 'center' | 'end'
  className?: string
}

const Label = ({label, required, align = 'center', className = '', children}: LabelProps) => {

  const style = children ? ' my-5 py-8 ' : ' py-4 '

  const itemsAligment = align === 'center' ? ' items-center ' : ' items-end '

  return (
    <>
      <label className={'w-full sm:block md:flex ' + itemsAligment + style}>
        <span className={'shrink-0 block text-xl text-dark-blue font-bold mr-8 ' + className}>
          {required && <b className="text-red-400">* </b>}
          {label}
        </span>
        <div className='w-full'>
        {children}
        </div>
      </label>
    </>
  ) 
}


export default Label