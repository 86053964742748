import { useNumbers } from '../../api/admin/numbers'
import { User } from '../../api/user'
import Label from '../../components/design-system/Label'
import Loader from '../../components/design-system/Loader'

interface Props {
  user: User
}

const Numbers = ({user}: Props) => {
  const {data: numbers, isLoading} = useNumbers() 
  return (
    <>
      <div className="px-44 py-20">
        <h1 className="text-3xl font-bold text-dark-blue mb-10">NUMBERS</h1>

        {numbers && Object.keys(numbers).map((objectKey: string, index: number) => {
          const integerPart = Math.trunc(numbers[objectKey])
          const decimalPart = Math.floor((numbers[objectKey] - integerPart) * 100)

          return (
            <div key={index}>
            <Label label={objectKey.replace(/_/g, ' ').toUpperCase() + ':'} align='end' className="w-60">
            <div className='flex justify-center'>
            {isLoading ?
              <Loader/>
              :
              <span className='flex items-end'>
                <p className='font-bold text-6xl'>{integerPart}</p>
                <p className='font-bold text-xl'>{'.' + decimalPart}</p>
                <p className='font-bold text-3xl ml-2'>$</p>
              </span>
            }
            </div>
            </Label>
            </div>
          )
        })}
      </div>
    </>
  )
}


export default Numbers