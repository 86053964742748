import React, { useState } from "react";
import Button from "../components/design-system/Button";
import LoginForm from "../components/LogInForm";
import SignUpForm from "../components/SignUpFrom";
import { PostItem } from "../components/PostsList";
import Image from "../components/design-system/Image";
import { useLanding } from "../api/landing";
import FORYOU_LOGO from "../assets/4you-logo.png";
// import SMOKE_TEXTURE from "../assets/smoke-texture.jpeg";
import RegisterToNewsletterForm from "./RegisterToNewsletterForm";
import ProtectedImage from "../components/design-system/ProtectedImage";
import Loader from "../components/design-system/Loader";
import CONTENT from "../assets/content_light_blue.png";
import LanguagePicker from "../components/LanguagePicker";
import { Trans, useTranslation } from "react-i18next";
import { FaUserPlus } from "react-icons/fa";
import { FaMagnifyingGlass } from "react-icons/fa6";
import { FaGrinStars } from "react-icons/fa";
import { FaLockOpen } from "react-icons/fa";
import { FaUserLock } from "react-icons/fa";
import { FaClipboardCheck } from "react-icons/fa";
import { FaExchangeAlt } from "react-icons/fa";
import { FaFileImage } from "react-icons/fa";
import { FaMedal } from "react-icons/fa";
import IMAGE1 from "../assets/1millidollars-IIAh6Gl0oEQ-unsplash.jpg";
import IMAGE2 from "../assets/engineer-style-mF3ouZe8aZk-unsplash.jpg";
import IMAGE3 from "../assets/garin-chadwick-0qodft4ZK7Q-unsplash.jpg";
import IMAGE4 from "../assets/jc-gellidon-Ikaw1-lupmI-unsplash.jpg";
import IMAGE5 from "../assets/josh-duke-No1TxIvS5Lw-unsplash.jpg";
import IMAGE6 from "../assets/nahima-aparicio-Q6PrbE7ZbSg-unsplash.jpg";
import IMAGE7 from "../assets/norbert-buduczki-QCGSYQr8I_M-unsplash.jpg";
import IMAGE8 from "../assets/thiago-reboucas-Eu6Y58PAPRo-unsplash.jpg";
import IMAGE9 from "../assets/thiago-reboucas-IT7udPaoPxc-unsplash.jpg";
import IMAGE10 from "../assets/vivek-ntveIrrPlbI-unsplash.jpg";

import SMOKE from "../assets/smoke.png";

const LIGHT_BLUE = "#35A7E7";

const Landing: React.FC = () => {
  const { t } = useTranslation();
  const [pageMode, setPageMode] = useState<"login" | "signup">("login");

  const { data: landing, isLoading } = useLanding();

  const focusOnLogIn = () => {
    setPageMode("login");
    window.location.href = "#form";
  };
  const focusOnSignUp = () => {
    setPageMode("signup");
    window.location.href = "#form";
  };

  const focusOnSuscribe = () => {
    window.location.href = "#form";
  };

  if (isLoading) return <Loader />;

  return (
    <>
      {/* Header */}
      <div className="fixed  bg-dark-blue text-white h-20 w-full z-50">
        <div className="flex justify-between">
          <Image src={FORYOU_LOGO} alt="4 YOU logo" className="h-16" />
          <div className="p-2 pr-8 flex space-x-2 items-center">
            {/* <Button  onClick={() => {
              setPageMode('signup')
              window.location.href = '#form'
            }} mode="outlined" disabled>
              SIGN UP
            </Button>
            <Button
            onClick={() => {
              setPageMode('login')
              window.location.href = '#form'
            }} className='text-dark-blue'
            disabled >SIGN IN</Button> */}
            <LanguagePicker />

            <button
              onClick={() => {
                window.location.href = "#subscribe";
              }}
              className="bg-light-blue hover:bg-blue-600 text-white font-semibold py-3 px-6 rounded-md"
            >
              {t("common.actions.subscribe")}
            </button>
            {/* <Button
              onClick={() => {
                window.location.href = "#form";
              }}
              className="text-dark-blue"
            >
              {t("common.actions.subscribe")}
            </Button> */}
          </div>
        </div>
      </div>

      {/* <div className="flex pt-20" style={{ height: "100vh" }} id="form">
        <Image
          url
          src={landing?.first_pic}
          alt="creator"
          className="w-1/2 bg-fixed"
        />
        <div className="w-1/2 p-16 font-sans">
          <h1
            data-i18n="[html]content.body"
            className="text-5xl mb-10 font-bold"
          >
            <Trans i18nKey="landing.title" />
          </h1>
          <h1 className="text-3xl mb-10 font-bold ">{t("landing.subtitle")}</h1>
          {pageMode === "login" ? (
            <LoginForm />
          ) : (
            <SignUpForm onSignUpSuccess={() => setPageMode("login")} />
          )}

          <RegisterToNewsletterForm />
        </div>
      </div> */}

      {/* <!-- Hero Section --> */}
      <section
        className={`flex flex-col items-center justify-center h-screen bg-cover bg-center bg-dark-blue text-white`}
      >
        {/* <Image
          url
          src={landing?.first_pic}
          alt="creator"
          className="w-full h-full opacity-100 relative"
        /> */}
        <div className="h-full w-full ">
          <div className="smoke-wrap">
            <img className="smoke h-32" src={SMOKE} alt="smoke" />
          </div>
          <div className="smoke-wrap">
            <img className="smoke2 h-32" src={SMOKE} alt="smoke" />
          </div>
          <div className="smoke-wrap">
            <img className="smoke3 h-32" src={SMOKE} alt="smoke" />
          </div>
        </div>
        <div className="absolute">
          <h1 className="text-5xl md:text-6xl font-bold mb-4 text-center">
            {t("landing.hero.title")}
          </h1>
          <p className="text-xl md:text-2xl mb-8 text-center">
            {t("landing.hero.subtitle")}
          </p>
          <div className="space-x-4 flex justify-center lg:justify-start">
            <button
              className="bg-light-blue hover:bg-blue-600 text-white font-semibold py-3 px-6 rounded-md"
              onClick={() => {
                window.location.href = "#subscribe";
              }}
            >
              {t("common.actions.subscribe")}
            </button>
            <button
              className="bg-transparent border-2 border-white hover:bg-white hover:text-gray-900 font-semibold py-3 px-6 rounded-md"
              onClick={() => {
                window.location.href = "#more";
              }}
            >
              {t("common.actions.discover")}
            </button>
          </div>
        </div>
      </section>

      {/* <!-- Key Features Section --> */}
      <section
        className="py-16 px-8 md:px-16 bg-dark-blue text-center text-white"
        id="more"
      >
        <h2 className="text-4xl font-semibold mb-8">
          {t("landing.keyFeatures.title")}
        </h2>
        <div className="flex h-screen">
          <div className="flex justify-start items-center w-1/2">
            <Image
              src={IMAGE1}
              alt="exclusive-content"
              className="h-2/3 w-1/5 rounded-xl"
              loading="lazy"
            />
            <Image
              src={IMAGE2}
              alt="exclusive-content"
              className="mt-16 ml-8 md:ml-16 h-2/3 w-1/5 rounded-xl"
              loading="lazy"
            />
            <Image
              src={IMAGE3}
              alt="exclusive-content"
              className="mt-32 ml-8 md:ml-16 h-2/3 w-1/5 rounded-xl"
              loading="lazy"
            />
          </div>
          <div className="flex-1 flex flex-col justify-center items-center w-1/2 z-10">
            <FaMedal className="h-16 w-16 mb-6" />
            <h3 className="text-3xl md:text-4xl font-bold mb-2">
              {t("landing.keyFeatures.exclusiveContent.title")}
            </h3>
            <p className="text-lg">
              {t("landing.keyFeatures.exclusiveContent.description")}
            </p>
          </div>
        </div>

        <div className="flex h-screen">
          <div className="flex flex-col justify-center items-center w-1/2 z-10">
            <FaExchangeAlt className="h-16 w-16 mb-6" />
            <h3 className="text-3xl md:text-4xl font-bold mb-2">
              {t("landing.keyFeatures.flexibleMembership.title")}
            </h3>
            <p className="text-lg">
              {t("landing.keyFeatures.flexibleMembership.description")}
            </p>
          </div>
          <div className="flex flex-row-reverse justify-start items-center w-1/2 px-2">
            <Image
              src={IMAGE4}
              alt="exclusive-content"
              className="h-2/3 w-1/5 rounded-xl"
              loading="lazy"
            />
            <Image
              src={IMAGE5}
              alt="exclusive-content"
              className="mt-16 mr-8 md:mr-16 h-2/3 w-1/5 rounded-xl"
              loading="lazy"
            />
            <Image
              src={IMAGE6}
              alt="exclusive-content"
              className="mt-32 mr-8 md:mr-16 h-2/3 w-1/5 rounded-xl"
              loading="lazy"
            />
          </div>
        </div>

        <div className="flex h-screen">
          <div className="flex justify-center items-center w-1/2">
            <Image
              src={IMAGE7}
              alt="exclusive-content"
              className="h-2/3 w-1/5 rounded-xl"
              loading="lazy"
            />
            <Image
              src={IMAGE8}
              alt="exclusive-content"
              className="mt-16 ml-8 md:ml-16 h-2/3 w-1/5 rounded-xl"
              loading="lazy"
            />
            <Image
              src={IMAGE9}
              alt="exclusive-content"
              className="mt-32 ml-8 md:ml-16 h-2/3 w-1/5 rounded-xl"
              loading="lazy"
            />
          </div>
          <div className="flex flex-col justify-center items-center w-1/2 z-10">
            <FaUserLock className="h-16 w-16 mb-6" />
            <h3 className="text-3xl md:text-4xl font-bold mb-2">
              {t("landing.keyFeatures.privacySecurity.title")}
            </h3>
            <p className="text-lg">
              {t("landing.keyFeatures.privacySecurity.description")}
            </p>
          </div>
        </div>
      </section>

      {/* <!-- How It Works Section --> */}
      <section className="py-16 px-8 md:px-16 bg-dark-blue text-white text-center ">
        <h2 className="text-4xl font-semibold mb-16">
          {t("landing.howItWorks.title")}
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div className="p-6 border border-light-blue rounded-lg flex flex-col items-center">
            <FaUserPlus className="h-16 w-16 mb-6" />
            <h3 className="text-xl font-bold mb-2">
              {t("landing.howItWorks.create_account.title")}
            </h3>
            <p>{t("landing.howItWorks.create_account.description")}</p>
          </div>
          <div className="p-6 border border-light-blue rounded-lg flex flex-col items-center">
            <FaMagnifyingGlass className="h-16 w-16 mb-6" />

            <h3 className="text-xl font-bold mb-2">
              {t("landing.howItWorks.browse_and_subscribe.title")}
            </h3>
            <p>{t("landing.howItWorks.browse_and_subscribe.description")}</p>
          </div>
          <div className="p-6 border border-light-blue rounded-lg flex flex-col items-center">
            <FaFileImage className="h-16 w-16 mb-6" />

            <h3 className="text-xl font-bold mb-2">
              {t("landing.howItWorks.exclusive_access.title")}
            </h3>
            <p>{t("landing.howItWorks.exclusive_access.description")}</p>
          </div>
        </div>
      </section>

      {/* <!-- Testimonials Section --> */}
      {/* <section className="py-16 px-8 md:px-16 bg-gray-800 text-center">
        <h2 className="text-3xl font-semibold mb-8">
          See What Fans & Creators Are Saying
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="p-6 bg-gray-700 rounded-lg">
            <p>
              "The best platform to access exclusive content from my favorite
              creators!"
            </p>
            <p className="mt-4 font-semibold">- Alex, Fan</p>
          </div>
          <div className="p-6 bg-gray-700 rounded-lg">
            <p>
              "4You Only makes it easy for me to connect with my fans and share
              exclusive content!"
            </p>
            <p className="mt-4 font-semibold">- Jamie, Creator</p>
          </div>
        </div>
      </section> */}

      {/* <!-- Creator Spotlight Section --> */}
      <section className="py-16 px-8 md:px-16 bg-dark-blue text-white text-center flex flex-col items-center">
        <h2 className="text-4xl font-semibold mb-16">
          {t("landing.featuredCreators.title")}
        </h2>

        <div className="md:w-2/3 lg:w-1/3">
          <PostItem
            demo
            post={{
              id: "1",
              profile_id: "1",
              description: landing?.first_description,
              file: landing?.first_post_file,
              profile_pic: landing?.first_profile_pic,
              username: landing?.first_username,
              liked: false,
              likes_count: 1643,
            }}
            textColor="white"
          />
          <div className="h-20" />
          <PostItem
            demo
            lock
            post={{
              id: "2",
              profile_id: "1",
              description: "Get ready for tonight live show!! 🤫",
              file: "",
              profile_pic: landing?.first_profile_pic,
              username: landing?.first_username,
              liked: false,
              likes_count: 566,
            }}
          />
          <div className="h-20" />
          <PostItem
            demo
            post={{
              id: "3",
              profile_id: "2",
              description: landing?.second_description,
              file: landing?.second_post_file,
              profile_pic: landing?.second_profile_pic,
              username: landing?.second_username,
              liked: false,
              likes_count: 377,
            }}
          />
        </div>
      </section>

      {/* <!-- Call to Action Section --> */}
      <section className="py-16 px-8 md:px-16 bg-light-blue text-center">
        <h2 className="text-3xl font-semibold mb-4 text-white">
          {t("landing.callToAction.title")}
        </h2>
        <button
          className="bg-white text-light-blue font-semibold py-3 px-8 rounded-md hover:bg-gray-100"
          onClick={() => {
            window.location.href = "#subscribe";
          }}
        >
          {t("landing.callToAction.callToAction")}
        </button>
      </section>

      {/* <!-- FAQ Section --> */}
      <section className="py-16 px-8 md:px-16 bg-dark-blue text-white text-center min-h-screen">
        <h2 className="text-3xl font-semibold mb-8">
          {t("landing.faq.title")}
        </h2>
        <div className="flex items-center justify-center h-full">
          <div className="space-y-6 text-left max-w-2xl mx-auto self">
            <div className="bg-gray-700 p-4 rounded-md">
              <h3 className="font-semibold text-lg">
                {t("landing.faq.question1")}
              </h3>
              <p className="mt-2">{t("landing.faq.answer1")}</p>
            </div>
            <div className="bg-gray-700 p-4 rounded-md">
              <h3 className="font-semibold text-lg">
                {t("landing.faq.question2")}
              </h3>
              <p className="mt-2">{t("landing.faq.answer2")}</p>
            </div>
            <div className="bg-gray-700 p-4 rounded-md">
              <h3 className="font-semibold text-lg">
                {t("landing.faq.question3")}
              </h3>
              <p className="mt-2">{t("landing.faq.answer3")}</p>
            </div>
            <div className="bg-gray-700 p-4 rounded-md">
              <h3 className="font-semibold text-lg">
                {t("landing.faq.question4")}
              </h3>
              <p className="mt-2">{t("landing.faq.answer4")}</p>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- Subscribe Section --> */}
      <section
        className="flex flex-col items-center py-16 px-8 md:px-16 bg-dark-blue text-white text-center min-h-9/10"
        id="subscribe"
      >
        <h2 className="text-3xl font-semibold mb-8">
          {t("landing.subscribe.title")}
        </h2>

        <div className="md:w-1/2">
          <RegisterToNewsletterForm />
        </div>
      </section>

      {/* <!-- Footer Section --> */}
      <footer className="py-8 px-8 md:px-16 bg-gray-900 text-center text-gray-500">
        <div className="space-x-4">
          <a href="#" className="hover:underline">
            {t("footer.about")}
          </a>
          <a href="#" className="hover:underline">
            {t("footer.privacyPolicy")}
          </a>
          <a href="#" className="hover:underline">
            {t("footer.termsOfService")}
          </a>
          <a href="#" className="hover:underline">
            {t("footer.contactUs")}
          </a>
        </div>
        <p className="mt-4">&copy; {t("footer.allRightsReserved")}</p>
      </footer>

      {/* <div className="py-10  flex flex-col mb-20">
        <h2 className="text-3xl font-bold text-center mb-10 mt-10">
          Share exclusive content with your fans!
        </h2>

        <div className="flex justify-center">
          <div className="w-1/3">
            <PostItem
              demo
              post={{
                id: "1",
                profile_id: "1",
                description: landing?.first_description,
                file: landing?.first_post_file,
                profile_pic: landing?.first_profile_pic,
                username: landing?.first_username,
                liked: false,
                likes_count: 1643,
              }}
            />
            <div className="h-20" />
            <PostItem
              demo
              lock
              post={{
                id: "2",
                profile_id: "1",
                description: "Get ready for tonight live show!! 🤫",
                file: "",
                profile_pic: landing?.first_profile_pic,
                username: landing?.first_username,
                liked: false,
                likes_count: 566,
              }}
            />
            <div className="h-20" />
            <PostItem
              demo
              post={{
                id: "3",
                profile_id: "2",
                description: landing?.second_description,
                file: landing?.second_post_file,
                profile_pic: landing?.second_profile_pic,
                username: landing?.second_username,
                liked: false,
                likes_count: 377,
              }}
            />
          </div>
        </div>
      </div> */}

      {/* <div className="flex justify-center">
        <h2 className="text-2xl font-bold text-center mb-10 mt-10 w-1/3">
          Support your favorites creators or share with your fans now!
        </h2>
      </div> */}

      {/* <div className='space-y-4 justify-items-center bg-slate-600'> */}
      {/* <div className='flex justify-center'>
        <Button onClick={focusOnSignUp} mode="outlined" className='w-52'>
            SIGN UP
        </Button>
        </div>
      <div className='flex justify-center mb-24'>
        <Button onClick={focusOnLogIn} className='w-52 text-white mt-5'>SIGN IN</Button>
      </div> */}
      {/* <div className="flex justify-center mb-24">
        <Button onClick={focusOnSuscribe} className="w-52 text-white mt-5">
          SUSCRIBE
        </Button>
      </div>
      <div className="h-64 w-full bg-dark-blue flex justify-center items-center">
        <Image src={FORYOU_LOGO} alt="4 YOU logo" />
      </div> */}
      {/* <a href="https://www.flaticon.com/free-icons/seo-and-web" title="seo and web icons">Seo and web icons created by Iconcity - Flaticon</a> */}
    </>
  );
};

export default Landing;

/* <div className="max-w-md mx-auto bg-white rounded-xl shadow-md overflow-hidden md:max-w-2xl">
<div className="lg:flex">
  <div className="md:shrink-0">
    <Image
      className="h-48 w-full md:h-full md:w-48"
      src="src/assets/Capture d’écran 2022-12-19 à 12.40.19.png"
      alt="Modern building architecture"
    />
  </div>
  <div className="p-8">
    <div className="uppercase tracking-wide text-sm text-indigo-500 font-semibold">
      Company retreats
    </div>
    <a
      href="#"
      className="block mt-1 text-lg leading-tight font-medium text-black hover:underline"
    >
      Incredible accommodation for your team
    </a>
    <p className="mt-2 text-slate-500">
      Looking to take your team away on a retreat to enjoy awesome food and take in some
      sunshine? We have a list of places to do just that.
    </p>
  </div>
</div>
</div> */

// <div className="min-h-screen bg-gray-900 text-white font-sans">

// {/* <!-- Key Features Section --> */}
// <section className="py-16 px-8 md:px-16 bg-gray-800 text-center">
//   <h2 className="text-3xl font-semibold mb-8">Why Choose 4You Only?</h2>
//   <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
//     <div className="p-6 bg-gray-700 rounded-lg">
//       <h3 className="text-xl font-bold mb-2">Exclusive Content</h3>
//       <p>
//         Access exclusive, personalized content not available anywhere
//         else.
//       </p>
//     </div>
//       <div className="p-6 bg-gray-700 rounded-lg">
//         <h3 className="text-xl font-bold mb-2">Direct Interaction</h3>
//         <p>
//           Connect with creators directly through messages, requests, and
//           more.
//         </p>
//       </div>
//       <div className="p-6 bg-gray-700 rounded-lg">
//         <h3 className="text-xl font-bold mb-2">Flexible Memberships</h3>
//         <p>
//           Subscribe to individual creators on a monthly basis with flexible
//           options.
//         </p>
//       </div>
//       <div className="p-6 bg-gray-700 rounded-lg">
//         <h3 className="text-xl font-bold mb-2">Privacy & Security</h3>
//         <p>
//           Your privacy is our priority with secure payments and confidential
//           interactions.
//         </p>
//       </div>
//     </div>
//   </section>

//   {/* <!-- How It Works Section --> */}
//   <section className="py-16 px-8 md:px-16 bg-gray-900 text-center">
//     <h2 className="text-3xl font-semibold mb-8">How 4You Only Works</h2>
//     <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
//       <div className="p-6 bg-gray-800 rounded-lg">
//         <h3 className="text-xl font-bold mb-2">1. Create an Account</h3>
//         <p>Sign up in minutes and start exploring creators.</p>
//       </div>
//       <div className="p-6 bg-gray-800 rounded-lg">
//         <h3 className="text-xl font-bold mb-2">2. Browse & Subscribe</h3>
//         <p>
//           Find creators that match your interests and subscribe to their
//           channels.
//         </p>
//       </div>
//       <div className="p-6 bg-gray-800 rounded-lg">
//         <h3 className="text-xl font-bold mb-2">3. Get Exclusive Access</h3>
//         <p>
//           Access personalized content and connect directly with creators.
//         </p>
//       </div>
//     </div>
//   </section>

//   {/* <!-- Testimonials Section --> */}
//   <section className="py-16 px-8 md:px-16 bg-gray-800 text-center">
//     <h2 className="text-3xl font-semibold mb-8">
//       See What Fans & Creators Are Saying
//     </h2>
//     <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
//       <div className="p-6 bg-gray-700 rounded-lg">
//         <p>
//           "The best platform to access exclusive content from my favorite
//           creators!"
//         </p>
//         <p className="mt-4 font-semibold">- Alex, Fan</p>
//       </div>
//       <div className="p-6 bg-gray-700 rounded-lg">
//         <p>
//           "4You Only makes it easy for me to connect with my fans and share
//           exclusive content!"
//         </p>
//         <p className="mt-4 font-semibold">- Jamie, Creator</p>
//       </div>
//     </div>
//   </section>
//   {/* h-16 w-16 rounded-full border border-black'/> */}
//   {/* <!-- Creator Spotlight Section --> */}
//   <section className="py-16 px-8 md:px-16 bg-gray-900 text-center">
//     <h2 className="text-3xl font-semibold mb-8">Featured Creators</h2>
//     <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
//       {/* <!-- Repeat for each featured creator --> */}
//       <div className="p-6 bg-gray-800 rounded-lg">
//         <ProtectedImage
//           src={landing.first_profile_pic}
//           className="w-full h-40 object-cover rounded-md mb-4"
//         />
//         {/* <img
//           src="creator-image-url.jpg"
//           alt="Creator"
//           className="w-full h-40 object-cover rounded-md mb-4"
//         /> */}
//         <h3 className="text-xl font-bold">{landing?.first_username}</h3>
//         <p className="text-gray-300">
//           Brief bio of the creator (20-30 words).
//         </p>
//         <button className="mt-4 bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded-md">
//           Subscribe
//         </button>
//       </div>
//     </div>
//   </section>

//   {/* <!-- Call to Action Section --> */}
//   <section className="py-16 px-8 md:px-16 bg-blue-500 text-center">
//     <h2 className="text-3xl font-semibold mb-4">
//       Ready to Discover Content Made 4You Only?
//     </h2>
//     <button className="bg-white text-blue-500 font-semibold py-3 px-8 rounded-md hover:bg-gray-100">
//       Get Started Now
//     </button>
//   </section>

//   {/* <!-- FAQ Section --> */}
//   <section className="py-16 px-8 md:px-16 bg-gray-800 text-center">
//     <h2 className="text-3xl font-semibold mb-8">
//       Frequently Asked Questions
//     </h2>
//     <div className="space-y-6 text-left max-w-2xl mx-auto">
//       <div className="bg-gray-700 p-4 rounded-md">
//         <h3 className="font-semibold text-lg">What is 4You Only?</h3>
//         <p className="mt-2">
//           4You Only is a platform where you can subscribe to your favorite
//           creators and access exclusive content directly.
//         </p>
//       </div>
//       <div className="bg-gray-700 p-4 rounded-md">
//         <h3 className="font-semibold text-lg">
//           How is my privacy protected?
//         </h3>
//         <p className="mt-2">
//           We use secure payment processing and offer privacy settings to
//           protect your data.
//         </p>
//       </div>
//     </div>
//   </section>

//   {/* <!-- Footer Section --> */}
//   <footer className="py-8 px-8 md:px-16 bg-gray-900 text-center text-gray-500">
//     <div className="space-x-4">
//       <a href="#" className="hover:underline">
//         About
//       </a>
//       <a href="#" className="hover:underline">
//         Privacy Policy
//       </a>
//       <a href="#" className="hover:underline">
//         Terms of Service
//       </a>
//       <a href="#" className="hover:underline">
//         Contact Us
//       </a>
//     </div>
//     <p className="mt-4">&copy; 2023 4You Only. All rights reserved.</p>
//   </footer>
// </div>
