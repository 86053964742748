import { User } from '../api/user'
import PostsList from '../components/PostsList'

interface Props {
  user: User
}

const Home = ({user}: Props ) => {

  return (
    <>
      <div className="p-8">
        
        <PostsList />
      </div>
    </>
  )
}

export default Home