/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Profile,
  useProfileUpdate,
  UpdateProfilePayload,
  useProfileShow,
  useProfileCreate,
  CreateProfilePayload,
} from "../api/profile";
import { User, USER_QUERY_KEY } from "../api/user";
import Button from "./design-system/Button";
import Image from "./design-system/Image";
import Label from "./design-system/Label";
import Loader from "./design-system/Loader";
import ProtectedImage from "./design-system/ProtectedImage";
import TextArea from "./design-system/TextArea";
import Subscription, { EditSubscription } from "./Subscription";

interface Props {
  user: User;
}

interface ProfileForm {
  profile_pic: File | null;
  bio: string;
  day_subscription: string;
  week_subscription: string;
  month_subscription: string;
}

interface ProfileFormProps {
  user: User;
  // profileForm: ProfileForm
  // setProfileForm?: React.Dispatch<React.SetStateAction<ProfileForm>>
  currentPic?: string;
  submitTitle: string;
  profile?: Profile | null;
  onSubmit: (profileForm: ProfileForm) => void;
}

const CREATE_PROFILE_PAYLOAD: ProfileForm = {
  bio: "",
  profile_pic: null,
  day_subscription: "7.99",
  week_subscription: "14.99",
  month_subscription: "23.99",
};
const UPDATE_PROFILE_PAYLOAD: ProfileForm = {
  bio: "",
  profile_pic: null,
  day_subscription: "",
  week_subscription: "",
  month_subscription: "",
};

const SettingsProfileForm = ({
  user,
  profile = null,
  currentPic = "",
  submitTitle,
  onSubmit,
}: ProfileFormProps) => {
  const [profileForm, setProfileForm] = useState<ProfileForm>(
    ({ ...profile, profile_pic: null } as ProfileForm) || CREATE_PROFILE_PAYLOAD
  );
  const [image, setImage] = useState<string>("");

  return (
    <form className={" block my-5 items-center py-8 "}>
      <Label label="CREATOR PROFILE:" />
      <div className="w-full flex justify-center">
        <div className="flex flex-col pt-4 ">
          <div className="flex items-center justify-center self-center pb-2 rounded-full text-center w-32 h-32 ">
            {currentPic && !image ? (
              <ProtectedImage
                src={currentPic}
                className="relative w-32 h-32 rounded-full"
              />
            ) : (
              <Image src={image} className="relative w-32 h-32 rounded-full" />
            )}
            <label
              htmlFor="dropzone-file"
              className={
                "absolute flex flex-col items-center justify-center w-32 h-32 rounded-full border-2 border-gray-300 border-dashed cursor-pointer bg-gray-700 opacity-20 hover:opacity-80  "
              }
            >
              <div className="ablolute">
                <div className="flex flex-col items-center justify-center pt-5 pb-6">
                  <svg
                    aria-hidden="true"
                    className="w-10 h-10 mb-3 text-gray-400"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                    ></path>
                  </svg>
                  <p className="mb-2 text-xs text-gray-500 dark:text-gray-400">
                    <span className="font-semibold">Click to upload</span> or
                    drag and drop
                  </p>
                </div>

                <input
                  id="dropzone-file"
                  name="image"
                  type="file"
                  accept="image/*"
                  className="hidden"
                  onChange={(event) => {
                    if (event.target.files && event.target.files[0]) {
                      setProfileForm({
                        ...profileForm,
                        profile_pic: event.target.files[0],
                      });
                      setImage(URL.createObjectURL(event.target.files[0]));
                    }
                  }}
                />
              </div>
            </label>
          </div>

          <div className="flex justify-center">
            <button
              disabled
              className="text-black font-bold algin-center text-xl  hover:text-light-blue"
            >
              <h3>{user.username}</h3>
            </button>
          </div>

          <TextArea
            rows={4}
            placeholder="Write a bio.. Tell about yourself this will help people to suscribe to your profile"
            value={profileForm.bio}
            onChange={(event) =>
              setProfileForm({ ...profileForm, bio: event.target.value })
            }
          />

          <div className="flex justify-between space-x-4 ">
            <EditSubscription
              title={"1 Day"}
              price={profileForm.day_subscription}
              setPrice={(event) =>
                setProfileForm({
                  ...profileForm,
                  day_subscription: event.currentTarget.value,
                })
              }
            />
            <EditSubscription
              title={"1 Week"}
              price={profileForm.week_subscription}
              setPrice={(event) =>
                setProfileForm({
                  ...profileForm,
                  week_subscription: event.currentTarget.value,
                })
              }
            />
            <EditSubscription
              title={"1 Month"}
              price={profileForm.month_subscription}
              setPrice={(event) =>
                setProfileForm({
                  ...profileForm,
                  month_subscription: event.currentTarget.value,
                })
              }
            />
          </div>

          <Button
            size="small"
            className="text-white self-center my-8"
            onClick={() => onSubmit(profileForm)}
          >
            {submitTitle}
          </Button>
        </div>
      </div>
    </form>
  );
};

export const CreateProfileForm = ({ user }: Props) => {
  const [displayFrom, setDisplayForm] = useState(false);
  const { mutate: createProfile, isLoading } = useProfileCreate();

  const [profileForm, setProfileForm] = useState<ProfileForm>(
    CREATE_PROFILE_PAYLOAD
  );
  const navigate = useNavigate();

  const queryClient = useQueryClient();

  return (
    <>
      {displayFrom ? (
        <SettingsProfileForm
          user={user}
          onSubmit={() => createProfile}
          submitTitle="CREATE YOUR PROFILE"
        />
      ) : (
        <Label label={"START POSTING CONTENT:"}>
          <>
            <Button
              size="small"
              className="text-white ml-8"
              onClick={() => setDisplayForm(true)}
            >
              BECOME A CREATOR
            </Button>
          </>
        </Label>
      )}
    </>
  );
};

export const UpdateProfileForm = ({ user }: Props) => {
  const { data: profile, isLoading } = useProfileShow(user.username);
  const { mutate: updateProfile } = useProfileUpdate();
  // const [profileForm, setProfileForm] = useState<ProfileForm>(UPDATE_PROFILE_PAYLOAD)

  if (isLoading) return <Loader />;
  if (!profile) return <p>An unexpected error occured</p>;

  // setProfileForm({ ...profile, profile_pic: null})

  return (
    <SettingsProfileForm
      user={user}
      profile={profile}
      onSubmit={(profileForm: ProfileForm) =>
        updateProfile(profileForm as UpdateProfilePayload)
      }
      currentPic={profile.profile_pic}
      submitTitle="UPDATE YOUR PROFILE"
    />
  );
};
