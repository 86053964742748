import { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { getSubscripions, Subscription } from "../api/subscription";
import Label from "../components/design-system/Label";
import Loader from "../components/design-system/Loader";
import ProtectedImage from "../components/design-system/ProtectedImage";
import { User } from "../api/user";

interface ProfileSubscriptionProps {
  subscription: Subscription;
}

const ProfileSubscription = ({ subscription }: ProfileSubscriptionProps) => {
  const expires_in = subscription.expires_in
    ? subscription.expires_in + " left"
    : "Expired";
  const navigate = useNavigate();

  return (
    <div className="w-40">
      <div className="flex justify-center">
        <ProtectedImage
          src={subscription.profile_pic}
          className="relative w-32 h-32 rounded-full"
        />
      </div>
      <div className="flex justify-center">
        <button
          onClick={() => navigate("/creator/" + subscription.profile_name)}
          className="text-xl font-bold text-center truncate hover:text-light-blue"
        >
          {subscription.profile_name}
        </button>
      </div>
      <div className="flex justify-center">
        <p className="text ">{expires_in}</p>
      </div>
    </div>
  );
};

interface Props {
  user: User;
}

const Subscriptions = ({ user }: Props) => {
  const [subscriptions, setSubscriptions] = useState<Subscription[] | []>([]);
  const [pastSubscriptions, setPastSubscriptions] = useState<
    Subscription[] | []
  >([]);

  useEffect(() => {
    if (user)
      getSubscripions().then((response) => {
        if (response.status === 200) {
          const [ongoing, past] = response.data.reduce(
            (acc: Array<Subscription[]>, item: Subscription) => {
              if (item.expires_in) {
                acc[0].push(item);
              } else {
                acc[1].push(item);
              }
              return acc;
            },
            [[], []]
          );

          setSubscriptions(ongoing);
          setPastSubscriptions(past);
        }
      });
  }, [user]);

  return (
    <div className="px-44 py-20">
      <h1 className="text-3xl font-bold text-dark-blue mb-10">SUBSCRIPTIONS</h1>

      <Label label="ONGOING" />
      <>
        {subscriptions.length ? (
          subscriptions.map((subscription) => {
            return (
              <div key={subscription.id}>
                <ProfileSubscription subscription={subscription} />
              </div>
            );
          })
        ) : (
          <div className="flex justify-center">
            <p className="">No ongoing subscriptions yet</p>
          </div>
        )}
      </>

      <div className="h-10" />

      <Label label="PAST" />
      <>
        {pastSubscriptions.length ? (
          pastSubscriptions.map((subscription) => {
            return (
              <div key={subscription.id}>
                <ProfileSubscription subscription={subscription} />
              </div>
            );
          })
        ) : (
          <div className="flex justify-center">
            <p className="">No past subscriptions yet</p>
          </div>
        )}
      </>
    </div>
  );
};

export default Subscriptions;
