import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

const ADMIN_USERS_KEY = ['admin', 'users']

const buildQueryKey = (kind: 'admin' | 'moderator' | 'creator' | 'lambda' | undefined) => {
  let key = ADMIN_USERS_KEY

  if (kind)
    key = [...key, kind]
  else
    key = [...key, 'all']

  return key
}

export const useUsers = (kind?: 'admin' | 'moderator' | 'creator'  | 'lambda') => useQuery(buildQueryKey(kind), async () => {
  
  const response = await axios.get('/admin/users', { params: { kind }})

  return response.data
})