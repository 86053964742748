/* eslint-disable @typescript-eslint/ban-ts-comment */
import axios from "axios";
import { useEffect, useState } from "react";
import {
  formatDiagnosticsWithColorAndContext,
  setSourceMapRange,
} from "typescript";
import Button from "../components/design-system/Button";
import Header from "../components/Header";
import Image from "../assets/creator_2.jpg";
import { Navigate, useNavigate } from "react-router-dom";
import Input from "../components/design-system/Input";
import { getUser, updateUser, User, useUserShow } from "../api/user";
import {
  CreateProfileForm,
  UpdateProfileForm,
} from "../components/SettingsProfile";
import Label from "../components/design-system/Label";
import Loader from "../components/design-system/Loader";

interface InputSettingProps {
  label?: string;
  placeholder?: string;
  value: string;
  type?: string;
  disabled?: boolean;
  required?: boolean;
  onChange?: (value: React.FormEvent<HTMLInputElement>) => void;
  name?: string;
  className?: string;
  labelClassName?: string;
}

const InputSettings = ({
  label,
  placeholder,
  value = "",
  type = "text",
  disabled = false,
  required = false,
  className = "",
  labelClassName = "",
  onChange,
  ...props
}: InputSettingProps) => {
  return (
    <input
      type={type}
      onChange={onChange}
      value={value}
      // value="tbone"
      placeholder={placeholder}
      disabled={disabled}
      className="mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-full text-sm shadow-sm placeholder-slate-400
            focus:outline-none focus:border-light-blue focus:ring-1 focus:ring-light-blue
            disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
            invalid:border-pink-500 invalid:text-pink-600
            focus:invalid:border-pink-500 focus:invalid:ring-pink-500
        "
      {...props}
    />
  );
};

interface Props {
  user: User;
}

const Settings = ({ user }: Props) => {
  const [userForm, setUserForm] = useState<User | undefined>(user);
  const [profileLinkCopiedToClipboard, setProfileLinkCopiedToClipboard] =
    useState(false);

  useEffect(() => {
    if (profileLinkCopiedToClipboard)
      setTimeout(() => setProfileLinkCopiedToClipboard(false), 1000);
  }, [profileLinkCopiedToClipboard]);

  useEffect(() => {
    setUserForm(user);
  }, [user]);

  const profileLink = `${window.location.origin}/creator/${user.profile_id}`;

  const handleUpdateUser = async () => {
    if (!userForm) return;

    updateUser({ username: userForm.username });
  };

  return (
    <>
      {/* <Header/> */}
      <div className="px-44 py-20">
        <h1 className="text-3xl font-bold text-dark-blue mb-10">SETTINGS</h1>

        {!user ? (
          <Loader />
        ) : (
          <>
            <form>
              <Label label={"USERNAME:"}>
                <div className="flex align-center">
                  <InputSettings
                    value={userForm ? userForm.username : ""}
                    onChange={(e) =>
                      userForm &&
                      setUserForm({
                        ...userForm,
                        username: e.currentTarget.value,
                      })
                    }
                  />
                  <Button
                    size="small"
                    className="text-white ml-8 self-center"
                    onClick={() => handleUpdateUser()}
                  >
                    SAVE
                  </Button>
                </div>
              </Label>
            </form>
            <UpdateProfileForm user={user} />
            {/* {!user.profile_id ? <CreateProfileForm user={user} /> : <UpdateProfileForm user={user}/>} */}
            {user.profile_id && (
              <Label label={"SHARABLE PROFILE LINK:"}>
                <div className="flex align-center">
                  <InputSettings disabled value={profileLink} />
                  <Button
                    size="small"
                    className="text-white ml-8 self-center"
                    onClick={() => {
                      navigator.clipboard.writeText(profileLink);
                      setProfileLinkCopiedToClipboard(true);
                    }}
                  >
                    {profileLinkCopiedToClipboard ? "COPIED" : "COPY"}
                  </Button>
                </div>
              </Label>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default Settings;
