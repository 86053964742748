import { QueryFunction, QueryKey, useQuery } from '@tanstack/react-query'
import axios from 'axios'


export interface Revenues {
  today: number
  this_month: number
  last_month: number
  last_three_months: number
  last_six_months: number
  last_year_months: number
  since_day_one: number
}

const REVENUES_QUERY_KEY = ['revenues']

export const useRevenuesShow = () => {
  return useQuery(REVENUES_QUERY_KEY, async () => {
    const response = await axios.get('/revenues')
  
    return response.data
  })
}