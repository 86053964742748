import { Navigate, Route, Routes } from "react-router-dom";
import { User } from "../api/user";
import Header from "../components/Header";
import { ProtectedComponent } from "../components/ProtectedComponent";
import Reports from "./admin/Reports";
import Users from "./admin/Users";
import Numbers from "./admin/Numbers";
import Creator from "./Creator";
import Home from "./Home";
import Revenues from "./Revenues";
import Settings from "./Settings";
import Subscriptions from "./Suscriptions";

interface ForYouProps {
  user: User;
}

const ForYou = ({ user }: ForYouProps) => {
  return (
    <>
      <Header user={user} />
      <div className="pt-20">
        <Routes>
          {/* <> */}
          <Route path="/" element={<Home user={user} />} />
          <Route path="/settings" element={<Settings user={user} />} />
          <Route path="/:username" element={<Creator user={user} />} />
          <Route
            path="/subscriptions"
            element={<Subscriptions user={user} />}
          />
          <Route path="/revenues" element={<Revenues user={user} />} />

          {user?.role && (
            <>
              <Route path="admin/users" element={<Users user={user} />} />
              <Route path="admin/reports" element={<Reports user={user} />} />
              <Route path="admin/numbers" element={<Numbers user={user} />} />
            </>
          )}
          <Route path="*" element={<Navigate to="/" />} />
          {/* </> */}
        </Routes>
      </div>
    </>
  );
};

const ProtectedForYou = () => (
  <ProtectedComponent>
    {(user: User) => <ForYou user={user} />}
  </ProtectedComponent>
);

export default ProtectedForYou;

{
  /* <Route path='/admin/users' element={<Users setIsLoggedIn={setIsLoggedIn} />} />
          <Route path='/admin/reports' element={<Reports setIsLoggedIn={setIsLoggedIn} />} /> */
}
{
  /* <Route path='/admin/numbers' element={
          <ProtectedComponent>
            <>
            {(user: User) => (<Numbers user={user} />)}
            </>
          </ProtectedComponent>}/>  */
}
{
  /* <Numbers setIsLoggedIn={setIsLoggedIn} />} /> */
}
