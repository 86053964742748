import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { RiAddFill } from 'react-icons/ri';
import { buildPostsQueryKey, createPost, CreatePostPayload, Post, usePostsCreate } from '../api/post';
import { User } from '../api/user';
import Button from './design-system/Button';
import Image from './design-system/Image';
import Modal from './design-system/Modal';
import TextArea from './design-system/TextArea';
import ToggleButton from './design-system/ToggleButton';

interface Props {
  showModal: boolean
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>
  user: User
  // appendNewPost: (post: Post) => void
}

interface PostForm {
  description: string
  file: File | null
  protected: boolean
}

const PostFormModal = ({ showModal, setShowModal, user }: Props) => {
  const [image, setImage] = useState<string>()
  const [postForm, setPostForm] = useState<PostForm>({file: null, description: '', protected: true})
  const { mutate: createPost } = usePostsCreate()
  const queryClient = useQueryClient()

  const handleCreatePost = async () => {
    if (!postForm.description || !postForm.file) return

    createPost(postForm as CreatePostPayload, { onSuccess: () => {
      queryClient.invalidateQueries(buildPostsQueryKey(user.username))
      setShowModal(false)
    }})

  }

  return (
    <>
      {/* <button className="h-12 w-full bg-slate-400 hover:bg-slate-800 font-bold text-2xl " onClick={() => setShowModal(true)}>
        +
      </button> */}
      <div className='flex justify-center'>
        <Button size="small" className='' onClick={() => setShowModal(true)}>
          <div className='flex items-center justify-center'>
            <RiAddFill className='h-6 w-6 mr-2' />
            <span>CREATE A POST</span>
          </div>
        </Button>
      </div>
      <Modal showModal={showModal}
       setShowModal={setShowModal}
       title={'Create a new post'}
       onPrimaryClick={handleCreatePost}
      >
        <>
        <div className="flex items-center justify-center self-center pb-2 text-center  ">
          <Image src={image} className="relative w-60 h-60"/>
          <label
            htmlFor="dropzone-file" className={'absolute flex flex-col items-center justify-center border-2 border-gray-300 border-dashed cursor-pointer bg-gray-700 opacity-20 hover:opacity-80 w-60 h-60'}>
              <div className="ablolute">
              <div className="flex flex-col items-center justify-center pt-5 pb-6">
                  <svg aria-hidden="true" className="w-10 h-10 mb-3 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                  <p className="mb-2 text-xs text-gray-500 dark:text-gray-400"><span className="font-semibold">Click to upload</span> or drag and drop</p>
              </div>
    
          
          <input id="dropzone-file" name="image" type="file" accept="image/*" className="hidden" onChange={(event) => {

            if (event.target.files && event.target.files[0]) {
              setPostForm({...postForm, file:  event.target.files[0]})
              setImage(URL.createObjectURL(event.target.files[0]));
            }
          }
          }/>
          </div>
          </label>
        </div>
        <div className="flex justify-center">
        <ToggleButton checked={postForm.protected} 
          onChange={(event) => setPostForm({...postForm, protected: event.target.checked})}>
          <>
          <p>Protect the image (Users will need to subscribe to see it)</p>
          </>
        </ToggleButton>
        </div>
        
        <div className="flex justify-center">
        <TextArea placeholder="Description of your picture/video" value={postForm.description} onChange={(e) => setPostForm({...postForm, description: e.target.value})}/>
        </div>
        </>
      </Modal>
    </>
  )
}

export default PostFormModal 
