interface Props {
  children: JSX.Element
  checked: boolean
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}
const ToggleButton = ({onChange, checked, children}: Props) => {
  return (
    <label className="relative inline-flex items-center cursor-pointer">
      <input type="checkbox" checked={checked} className="sr-only peer" onChange={onChange}/>
      <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-light-blue  rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all  peer-checked:bg-light-blue"></div>
      <span className="ml-3 text-sm font-medium text-gray-900 ">{children}</span>
    </label>
  )
}

export default ToggleButton

// dark:bg-dark-blue dark:peer-focus:ring-light-blue dark:border-gray-600 dark:text-gray-300