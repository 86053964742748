import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { usePostDestroy } from "../../api/admin/posts";
import { ADMIN_REPORTS_QUERY_KEY, useReports } from "../../api/admin/report";
import { Post } from "../../api/post";
import { User } from "../../api/user";
import Label from "../../components/design-system/Label";
import Loader from "../../components/design-system/Loader";
import Modal from "../../components/design-system/Modal";
import ProtectedImage from "../../components/design-system/ProtectedImage";
import ViewerModal from "../../components/ViewModal";

interface DeletePostButtonProps {
  postId: string;
}

const DeletePostButton = ({ postId }: DeletePostButtonProps) => {
  const [showModal, setShowModal] = useState(false);
  const { mutate: deletePost, isLoading } = usePostDestroy();
  const queryClient = useQueryClient();

  const handleDestroyPost = () => {
    deletePost(postId, {
      onSuccess: () => {
        setShowModal(false);
        queryClient.invalidateQueries(ADMIN_REPORTS_QUERY_KEY);
      },
    });
  };

  return (
    <>
      <button
        className="hover:underline text-light-blue"
        onClick={() => setShowModal(true)}
      >
        Delete post
      </button>

      <Modal
        showModal={showModal}
        setShowModal={setShowModal}
        title={"Are you sure want to destroy this post ?"}
        onPrimaryClick={() => handleDestroyPost()}
        primaryAction={isLoading ? <Loader /> : "OK"}
      >
        <p>You will not be able to back</p>
      </Modal>
    </>
  );
};

interface PostsHorizontalListProps {
  posts: Post[] | [];
}
const PostsHorizontalList = ({ posts }: PostsHorizontalListProps) => {
  const [selectedPost, setSelectedPost] = useState<Post | null>(null);
  const [index, setIndex] = useState<number>(0);

  return posts.length ? (
    <>
      <div className="overflow-x-scroll flex space-x-4">
        {posts.map((post, index) => (
          <div key={post.id} className="w-64">
            <div className="flex justify-end">
              <DeletePostButton postId={post.id} />
            </div>

            <div
              className="w-64 cursor-pointer"
              onClick={() => {
                setSelectedPost(post);
                setIndex(index);
              }}
            >
              <ProtectedImage
                src={post.file}
                className="aspect-square hover:opacity-50 "
              />
            </div>

            <b>{`${post.reports?.length} reports`}</b>

            <div className="h-24 overflow-scroll">
              {post.reports?.map((report) => (
                <div key={report.id}>
                  <div className="h-0.5 bg-slate-500 m-2" />
                  <p>
                    <b>{report.author}</b>
                  </p>
                  <p>
                    <b>Reason: </b>
                    {report.reason}
                  </p>
                  <p>
                    <b>Description: </b>
                    {report.description}
                  </p>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
      {selectedPost && (
        <ViewerModal
          post={selectedPost}
          setPost={setSelectedPost}
          posts={posts}
          index={index}
          setIndex={setIndex}
        />
      )}
    </>
  ) : (
    <p className="text-center">No posts yet</p>
  );
};

interface Props {
  user: User;
}

const Reports = ({ user }: Props) => {
  const { data, isLoading } = useReports();

  if (isLoading) return <Loader />;

  const reportedPosts = data;
  return (
    <>
      <div className="px-44 py-20">
        <h1 className="text-3xl font-bold text-dark-blue mb-10">REPORTS</h1>

        <Label label="NUMBER OF REPORTS:"></Label>

        <Label label="NEW POST REPORTED:"></Label>
        <PostsHorizontalList posts={reportedPosts} />

        <Label label="TOP POST REPORTED:"></Label>
      </div>
    </>
  );
};

export default Reports;
