import { useUsers } from "../../api/admin/users";
import { User } from "../../api/user";
import Label from "../../components/design-system/Label";
import Loader from "../../components/design-system/Loader";

interface Props {
  user: User;
}

interface UsersListProps {
  users: User[];
}

const UsersList = ({ users }: UsersListProps) => {
  if (!users || !users.length) return <p>There is no users yet</p>;

  return (
    <div className="flex space-x-4 overflow-x-scroll">
      {users?.map((user: User) => (
        <div key={user.id}>
          <p>
            <b>id:</b>
            {` ${user.id}`}
          </p>
          <p>
            <b>username:</b>
            {` ${user.username}`}
          </p>
          <p>
            <b>email:</b>
            {` ${user.email}`}
          </p>
          {user.profile_id && (
            <p>
              <b>profile_id:</b>
              {` ${user.profile_id}`}
            </p>
          )}
          {user.role && (
            <p>
              <b>role:</b>
              {` ${user?.role}`}
            </p>
          )}
        </div>
      ))}
    </div>
  );
};

const Users = ({ user }: Props) => {
  const { data: adminUsers, isLoading: isAdminLoading } = useUsers("admin");
  const { data: moderatorUsers, isLoading: isModeratorLoading } =
    useUsers("moderator");
  const { data: creatorUsers, isLoading: isCreatorLoading } =
    useUsers("creator");
  const { data: lambdaUsers, isLoading: isLambdaLoading } = useUsers("lambda");
  const { data: allUsers, isLoading: isAllUsersLoading } = useUsers();

  return (
    <>
      <div className="px-44 py-20">
        <h1 className="text-3xl font-bold text-dark-blue mb-10">USERS</h1>

        <Label label="NUMBER OF USERS:">
          <>250</>
        </Label>

        <Label label={`ADMIN USERS: ${adminUsers?.length}`}></Label>
        {isAdminLoading ? <Loader /> : <UsersList users={adminUsers} />}

        <Label label={`MODERATOR USERS: ${moderatorUsers?.length}`}></Label>
        {isModeratorLoading ? <Loader /> : <UsersList users={moderatorUsers} />}

        <Label label={`CREATOR USER: ${creatorUsers?.length}`}></Label>
        {isCreatorLoading ? <Loader /> : <UsersList users={creatorUsers} />}

        <Label label={`LAMBDA USERS: ${lambdaUsers?.length}`}></Label>
        {isLambdaLoading ? <Loader /> : <UsersList users={lambdaUsers} />}
      </div>

      <div className="flex justify-center">
        <table className="table-fixed border-2 border-dark-blue">
          <thead>
            <tr className="border-2 border-dark-blue">
              <th className="px-4 border-r-2 border-dark-blue">ID</th>
              <th className="px-4 border-r-2 border-dark-blue">USERNAME</th>
              <th className="px-4 border-r-2 border-dark-blue">CREATOR</th>
              <th className="px-4">ROLE</th>
            </tr>
          </thead>
          <tbody>
            {isAllUsersLoading ? (
              <Loader />
            ) : (
              allUsers &&
              allUsers.map((user: User) => (
                <tr key={user.id}>
                  <td className="px-4 border border-dark-blue">{user.id}</td>
                  <td className="px-4 border border-dark-blue">
                    {user.username}
                  </td>
                  <td className="px-4 border border-dark-blue">
                    {user.profile_id ? "YES" : "NO"}
                  </td>
                  <td className="px-4 border border-dark-blue">
                    {user?.role || "lambda"}
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Users;
