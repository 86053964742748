import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { USER_QUERY_KEY, useUserShow } from "./user";
import { useNavigate } from "react-router-dom";

export interface Profile {
  id: string;
  bio: string;
  profile_pic: string;
  name: string;
  day_subscription: string;
  week_subscription: string;
  month_subscription: string;
  suscribed: boolean;
}

const buildQueryKey = (username?: string) => ["profile", username];

export const useProfileShow = (username?: string) =>
  useQuery(
    buildQueryKey(username),
    async (): Promise<Profile> => {
      const response = await axios.get("/profiles/" + username);
      return response.data;
    },
    {
      enabled: !!username,
    }
  );

export interface UpdateProfilePayload {
  id: string;
  bio?: string;
  profile_pic?: File;
  day_subscription?: string;
  week_subscription?: string;
  month_subscription?: string;
}

export const useProfileUpdate = () => {
  const queryClient = useQueryClient();
  const { data: user } = useUserShow({ refetchOnMount: false });

  return useMutation({
    mutationFn: async (payload: UpdateProfilePayload) => {
      const formData = new FormData();

      if (payload.bio) formData.append("bio", payload.bio);
      if (payload.profile_pic)
        formData.append("profile_pic", payload.profile_pic);
      if (payload.day_subscription)
        formData.append("day_subscription", payload.day_subscription);
      if (payload.week_subscription)
        formData.append("week_subscription", payload.week_subscription);
      if (payload.month_subscription)
        formData.append("month_subscription", payload.month_subscription);

      const response = await axios.put("/profiles/" + payload.id, formData);

      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(buildQueryKey(user?.username));
    },
  });
};

export interface CreateProfilePayload {
  bio: string;
  profile_pic: File;
  day_subscription: string;
  week_subscription: string;
  month_subscription: string;
}

export const useProfileCreate = () => {
  const queryClient = useQueryClient();
  const { data: user } = useUserShow({ refetchOnMount: false });
  const navigate = useNavigate();

  return useMutation({
    mutationFn: async (payload: CreateProfilePayload) => {
      const formData = new FormData();
      formData.append("bio", payload.bio);
      formData.append("profile_pic", payload.profile_pic);
      formData.append("day_subscription", payload.day_subscription);
      formData.append("week_subscription", payload.week_subscription);
      formData.append("month_subscription", payload.month_subscription);

      const response = await axios.post("/profile", formData);

      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(buildQueryKey(user?.username));
      navigate(`/${user?.username}`);
    },
  });
};

// export const updateProfile = async (payload: UpdateProfilePayload) => {
//   const formData = new FormData()

//   console.log({payload})

//   if (payload.bio) formData.append('bio', payload.bio)
//   if (payload.profile_pic) formData.append('profile_pic', payload.profile_pic)
//   if (payload.day_subscription) formData.append('day_subscription', payload.day_subscription)
//   if (payload.week_subscription) formData.append('week_subscription', payload.week_subscription)
//   if (payload.month_subscription) formData.append('month_subscription', payload.month_subscription)

//   const response = await axios.put('/profiles/' + payload.id, formData)

//   return response
// }
