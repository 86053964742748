import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { Report } from "./report";

// export interface Report {
//   name: string
// }

export interface Post {
  id: string;
  description: string;
  file: string;
  username: string;
  profile_id: string;
  profile_pic: string;
  liked: boolean;
  likes_count: number;
  reports?: Report[];
}

export interface CreatePostPayload {
  description: string;
  file: File;
  protected: boolean;
}

export const buildPostsQueryKey = (username: string | undefined) => {
  if (!username) return ["posts"];

  return ["posts", username];
};

export const usePostsIndex = (username: string | undefined) =>
  useQuery(buildPostsQueryKey(username), async (): Promise<Post[]> => {
    const response = await axios.get("/posts", { params: { username } });

    return response.data;
  });

export const usePostsCreate = () =>
  useMutation(async (payload: CreatePostPayload) => {
    const formData = new FormData();
    formData.append("description", payload.description);
    formData.append("file", payload.file);
    formData.append("protected", payload.protected.toString());

    const response = await axios.post("/posts", formData);
    return response;
  });

export const getPosts = async (profile_id: string | null = null) => {
  const response = await axios.get("/posts", { params: { profile_id } });
  return response;
};

export const createPost = async (payload: CreatePostPayload) => {
  const formData = new FormData();
  formData.append("description", payload.description);
  formData.append("file", payload.file);
  formData.append("protected", payload.protected.toString());

  const response = await axios.post("/posts", formData);
  return response;
};
