import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useProfileShow } from "../api/profile";
import PostFormModal from "../components/PostFormModal";
import { Post, usePostsIndex } from "../api/post";
import Separator from "../components/design-system/Separator";
import ProtectedImage from "../components/design-system/ProtectedImage";
import ViewerModal from "../components/ViewModal";
import { User } from "../api/user";
import { SubscriptionButton } from "../components/Subscription";
import Loader from "../components/design-system/Loader";

// const AboutMe = () => {
//   return (
//     <div className="w-1/3">
//       <h3 className="text-lg font-bold">About me</h3>
//       <span className="flex items-center">
//         <BsGenderFemale />
//         <p>Female</p>
//       </span>
//       <span className="flex items-center">
//         <BsGenderFemale />
//         <p>Female</p>
//       </span>
//       <span className="flex items-center">
//         <BsGenderFemale />
//         <p>Female</p>
//       </span>
//       <span className="flex items-center">
//         <BsGenderFemale />
//         <p>Female</p>
//       </span>
//     </div>
//   )
// }

interface PostMozaicProps {
  posts: Post[] | undefined;
}
const PostsMozaic = ({ posts }: PostMozaicProps) => {
  const [selectedPost, setSelectedPost] = useState<Post | null>(null);
  const [index, setIndex] = useState<number>(0);

  return posts && posts.length ? (
    <>
      <div className="grid gap-1 grid-cols-3 grid-rows-3">
        {posts.map((post, index) => (
          <div
            key={post.id}
            className="hover:bg-black  cursor-pointer"
            onClick={() => {
              setSelectedPost(post);
              setIndex(index);
            }}
          >
            <ProtectedImage
              src={post.file}
              className="aspect-square hover:opacity-50 "
            />
          </div>
        ))}
      </div>
      {selectedPost && (
        <ViewerModal
          post={selectedPost}
          setPost={setSelectedPost}
          posts={posts}
          index={index}
          setIndex={setIndex}
        />
      )}
    </>
  ) : (
    <p className="text-center">No posts yet</p>
  );
};

interface Props {
  user: User;
}

const Creator = ({ user }: Props) => {
  const navigate = useNavigate();
  const { username } = useParams();

  const [showModal, setShowModal] = useState(false);
  const { data: profile, isLoading } = useProfileShow(username);
  const { data: posts, isLoading: isPostsLoading } = usePostsIndex(username);
  const isSelfProfile = user.username === username;

  if (!username) {
    navigate("/home");
    return null;
  }

  return (
    <>
      <div className="flex justify-center">
        <div className="w-1/2">
          {!profile ? (
            <Loader />
          ) : (
            <>
              {/* Creator */}
              <div className="flex py-8">
                <ProtectedImage
                  src={profile.profile_pic}
                  className="rounded-full h-56 w-56 border-2 shrink-0 border-slate-900"
                />
                <div className=" py-4 ml-16 w-full">
                  {!isSelfProfile && (
                    <div className="flex justify-end ">
                      <SubscriptionButton profile={profile} />
                    </div>
                  )}

                  <h1 className="text-4xl font-semibold text-dark-blue pb-8">
                    {profile.name}
                  </h1>
                  <pre>{profile.bio}</pre>
                </div>
              </div>

              {isSelfProfile && (
                <>
                  <PostFormModal
                    user={user}
                    showModal={showModal}
                    setShowModal={setShowModal}
                  />
                  <Separator />
                </>
              )}

              {/* Posts */}
              {isPostsLoading ? <Loader /> : <PostsMozaic posts={posts} />}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Creator;
