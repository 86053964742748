import { useEffect, useState } from "react";
import { Profile } from "../api/profile";
import { Duration, suscribe, SuscribePayload } from "../api/subscription";
import Button from "./design-system/Button";
import Input from "./design-system/Input";
import Modal from "./design-system/Modal";
import { loadStripe, StripeElementsOptions } from "@stripe/stripe-js";
import axios from "axios";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import ProtectedImage from "./design-system/ProtectedImage";

interface EditSubscriptionProps {
  title: string;
  price: string;
  setPrice: (value: React.FormEvent<HTMLInputElement>) => void;
}

export const EditSubscription = ({
  title,
  price,
  setPrice,
}: EditSubscriptionProps) => {
  return (
    <div className="w-32 rounded border-2 border-light-blue py-4">
      <strong className="flex justify-center shrink-0">{title}</strong>

      <strong className="flex justify-center shrink-0 text-2xl px-2">
        <b className="">
          <Input
            placeholder="Enter an amount"
            type="number"
            value={price}
            onChange={setPrice}
            step={0.01}
            className="my-0 text-right"
          />
        </b>
        <p>$</p>
      </strong>
      <div className="flex justify-center mt-2">
        <Button disabled={true} size="small">
          Buy
        </Button>
      </div>
    </div>
  );
};
interface SubscriptionProps {
  price: string;
  duration: Duration;
  onClick: (duration: Duration) => void;
}
const Subscription = ({ price, duration, onClick }: SubscriptionProps) => {
  const title = "1 " + duration.charAt(0).toUpperCase() + duration.slice(1);

  return (
    <div className="w-32 rounded border-2 border-light-blue py-4">
      <strong className="flex justify-center shrink-0">{title}</strong>
      <strong className="flex justify-center">
        <b className="text-2xl">{price}</b>$
      </strong>
      <div className="flex justify-center">
        <Button size="small" onClick={() => onClick(duration)}>
          Buy
        </Button>
      </div>
    </div>
  );
};
interface SubscriptionModalProps {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  profile: Profile;
}

export const SubscriptionModal = ({
  showModal,
  setShowModal,
  profile,
}: SubscriptionModalProps) => {
  const stripePromise = loadStripe("pk_test_oKhSR5nslBRnBZpjO6KuzZeX");

  const [clientSecret, setClientSecret] = useState("");
  const [selectedSubscription, setSelectedSubscription] =
    useState<SuscribePayload | null>(null);

  const appearance = {
    theme: "stripe",
    variables: {
      colorPrimary: "#05b5e1",
    },
  };
  const options = {
    clientSecret,
    appearance,
  };

  const handleSuscribe = (duration: Duration) => {
    setSelectedSubscription({ profile_id: profile.id, duration });

    axios
      .post("/create-payment-intent", {
        profile_id: profile.id,
        duration,
      })
      .then((res) => setClientSecret(res.data.clientSecret));

    // suscribe({profile_id: profile.id, duration}).then(response => {

    //   console.log({response})
    //   if (response.status === 201) {
    //     setShowModal(false)
    //   }

    // })
  };

  // useEffect(() => {
  //   // Create PaymentIntent as soon as the page loads
  //   axios.post('/create-payment-intent', {
  //     items: [{ id: 'xl-tshirt' }],
  //   }).then((res) => setClientSecret(res.data.clientSecret));

  // }, []);

  return (
    <>
      <Modal
        showModal={showModal}
        setShowModal={setShowModal}
        title={
          <div>
            Suscribe to <b>{profile.name}</b>
          </div>
        }
        //  onPrimaryClick={handleCreatePost}
        hideFooter
      >
        <>
          <div className="flex justify-center">
            <ProtectedImage
              src={profile.profile_pic}
              className="h-16 w-16 rounded-full border border-black"
            />
          </div>
          <div className="flex justify-center">
            <button
              disabled
              className="text-dark-blue font-bold text-xl hover:text-light-blue"
            >
              {profile.name}
            </button>
          </div>
          {selectedSubscription ? (
            clientSecret ? (
              <Elements
                options={options as StripeElementsOptions}
                stripe={stripePromise}
              >
                <CheckoutForm />
              </Elements>
            ) : (
              <p>...loading...</p>
            )
          ) : (
            <div className="flex space-x-4 py-10 justify-center">
              <Subscription
                duration="day"
                price={profile.day_subscription}
                onClick={handleSuscribe}
              />
              <Subscription
                duration="week"
                price={profile.week_subscription}
                onClick={handleSuscribe}
              />
              <Subscription
                duration="month"
                price={profile.month_subscription}
                onClick={handleSuscribe}
              />
            </div>
          )}
        </>
      </Modal>
    </>
  );
};

interface SuscribeButtonProps {
  profile: Profile;
}

export const SubscriptionButton = ({ profile }: SuscribeButtonProps) => {
  const [showModal, setShowModal] = useState(false);

  if (profile.suscribed) {
    return (
      <>
        <Button mode="outlined" size="small" disabled={true}>
          SUSCRIBED
        </Button>
        {/* {showModal && <SubscriptionModal profile={profile} setShowModal={setShowModal} showModal={showModal} />} */}
      </>
    );
  }

  return (
    <>
      <Button className="" size="small" onClick={() => setShowModal(true)}>
        SUSCRIBE
      </Button>
      {showModal && (
        <SubscriptionModal
          profile={profile}
          setShowModal={setShowModal}
          showModal={showModal}
        />
      )}
    </>
  );
};

export default Subscription;
